
const Footer =() => {
    return (
      <footer className="bg-gray-900 text-gray-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-sm text-center">
            <p>&copy; {new Date().getFullYear()} FindIt. All rights reserved.</p>
          </div>
        </div>
      </footer>
    )
}
export default Footer
