import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';


const Slideshow = () => {
  const slides = [
    {
      title: "Find What You Need, When You Need It",
      description: "Stop wasting time searching through boxes and drawers. Know exactly where everything is with digital tracking and instant search.",
      imageAlt: "Organized storage with easy access"
    },
    {
      title: "Reduce Stress and Clutter",
      description: "Transform chaos into calm with smart organization. A clutter-free space means a clutter-free mind.",
      imageAlt: "Clean and organized space"
    },
    {
      title: "Save Money, Stop Buying Duplicates",
      description: "Know what you own and where it is. Never waste money buying items you already have but can't find.",
      imageAlt: "Money savings concept"
    },
    {
      title: "Moving Made Simple",
      description: "Take the stress out of moving. Every box cataloged, every item tracked, making unpacking a breeze.",
      imageAlt: "Easy moving process"
    },
    {
      title: "Share Knowledge with Family",
      description: "Everyone knows where everything is. Share access with family members so important items are never lost.",
      imageAlt: "Family organization"
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  // Auto-advance slides
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((current) => (current + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((current) => (current - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative h-96 overflow-hidden">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-500 ease-in-out ${
                index === currentSlide ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <div className="absolute inset-0">
                <img
                  src={`/api/placeholder/1200/400`}
                  alt={slide.imageAlt}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gray-900/60" />
              </div>
              
              <div className="relative h-full flex items-center justify-center text-center px-4">
                <div className="max-w-2xl">
                  <h2 className="text-4xl font-bold text-white mb-4">
                    {slide.title}
                  </h2>
                  <p className="text-xl text-gray-200">
                    {slide.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        <button
          onClick={prevSlide}
          className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full hover:bg-white transition-colors"
        >
          <ChevronLeft className="h-6 w-6 text-gray-800" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full hover:bg-white transition-colors"
        >
          <ChevronRight className="h-6 w-6 text-gray-800" />
        </button>

        {/* Slide indicators */}
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`h-2 w-2 rounded-full transition-colors ${
                index === currentSlide ? 'bg-white' : 'bg-white/50'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slideshow;