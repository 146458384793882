import React, { useState } from 'react';
import { Search, Tag, Menu, Boxes, X, ArrowRight } from 'lucide-react';
import appStoreBadge from '../appstore.svg';
import Slideshow from './Slideshow';

const ModernLanding = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const features = [
    {
      title: 'Instant Search & Find',
      description: 'Locate any item in seconds with powerful search indexing',
      icon: Search,
    },
    {
      title: 'Smart AI Categorization',
      description: 'Automatically organize items with intelligent tagging',
      icon: Tag,
    },
    {
      title: 'Digital Storage Tracking',
      description: "Track every item's location across boxes, drawers, and spaces",
      icon: Boxes,
    },
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img src={require('../findit_nobg.png')} className="h-12 w-auto" />
              <span className="text-3xl">
                <span className="font-black text-slate-500">Find</span>
                <span className="font-black text-zinc-800 italic">It</span>
              </span>
            </div>
            {/* Desktop Navigation */}
            <div className="hidden sm:flex items-center space-x-8">
              <a href="#features" className="text-gray-600 hover:text-gray-900">Features</a>
              <button className="bg-slate-500 text-white px-6 py-2 rounded-full hover:bg-zinc-800 transition-colors">
                Download
              </button>
            </div>
            {/* Mobile menu button */}
            <div className="flex items-center sm:hidden">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="p-2 rounded-md text-gray-400 hover:text-gray-500"
              >
                {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        {mobileMenuOpen && (
          <div className="sm:hidden absolute w-full bg-white border-b border-gray-100">
            <div className="pt-2 pb-3 space-y-1 px-4">
              <a href="#features" className="block py-2 text-gray-600">Features</a>
              <button className="w-full mt-4 bg-slate-500 text-white px-6 py-2 rounded-full">
                Download
              </button>
            </div>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-slate-50 to-white">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-5xl sm:text-6xl font-bold text-gray-900 leading-tight">
            Organize with{' '}
            <span className="font-black text-slate-500">Find</span>
            <span className="font-black text-zinc-800 italic">It</span>
          </h1>
          <p className="mt-6 text-xl text-gray-600 max-w-2xl mx-auto">
            Experience instant search, AI-powered categorization, and digital storage tracking. 
            Seamlessly find what you need, when you need it.
          </p>
          <div className="mt-10 flex justify-center space-x-4">
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img
                src={appStoreBadge}
                alt="Download on the App Store"
                className="h-14 w-auto"
              />
            </a>
            <a href="#features">
              <button className="text-gray-600 px-8 py-3 rounded-full border border-gray-300 hover:border-gray-400 transition-colors flex items-center">
                Learn More
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900">Powerful Features</h2>
            <p className="mt-4 text-xl text-gray-600">
              Everything you need to get organized, in one app
            </p>
          </div>

          <div className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.title} className="relative group">
                <div className="bg-white p-8 rounded-2xl border border-gray-100 hover:border-slate-500 transition-colors">
                  <feature.icon className="h-12 w-12 text-slate-500" />
                  <h3 className="mt-4 text-xl font-semibold text-gray-900">
                    {feature.title}
                  </h3>
                  <p className="mt-2 text-gray-600">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-20 bg-slate-500">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white">Ready to Get Started?</h2>
          <button className="mt-8 bg-white text-slate-500 px-8 py-3 rounded-full hover:bg-slate-50 transition-colors">
            Download Now
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold text-white">FindIt</h3>
              <p className="mt-4 text-sm">
                Redefine your organizational experience.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white">Company</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <a
                    href="mailto:contact@findit.co"
                    className="text-sm hover:text-white"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white">Legal</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <a href="/privacy" className="text-sm hover:text-white">
                    Privacy Policy
                  </a>
                </li>
                {/* <li>
                  <a href="/terms" className="text-sm hover:text-white">
                    Terms of Service
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-sm text-center">
            <p>&copy; {new Date().getFullYear()} FindIt. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ModernLanding;
