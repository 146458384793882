import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Header from './../components/ui/header'
import Footer from './../components/ui/footer'

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState('');

  useEffect(() => {
    fetch('/privacy.md')  // This will look in the public folder
      .then(res => res.text())
      .then(setPolicy)
      .catch(err => console.error('Failed to load terms:', err));
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
        <div className="prose prose-lg max-w-none">
            <ReactMarkdown>
              {policy}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;