import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Header from './../components/ui/header'
import Footer from './../components/ui/footer'
// import remarkGfm from 'remark-gfm';
// import rehypeRaw from 'rehype-raw';

const TermsAndConditions = () => {
  const [terms, setTerms] = useState('');

  useEffect(() => {
    fetch('/terms.md')  // This will look in the public folder
      .then(res => res.text())
      .then(setTerms)
      .catch(err => console.error('Failed to load terms:', err));
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
        <div className="prose prose-lg max-w-none">
            <ReactMarkdown>
              {terms}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;